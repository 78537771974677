import SadFridge from "../assets/sad-fridge-nobg.png";
const SadFridgeImg = () => {
  return (
    <div>
      <img src={SadFridge} className="sadFridgeImg" />
    </div>
  );
};

export default SadFridgeImg;
