const Header = () => {
  return (
    // <div className="Header_Sad_Fridge">
    //   <div className="Title_Sad_Fridge">
    //     Sad-Fridge
    //     <hr className="h"></hr>
    //   </div>
    //   <div>
    //     <hr className="h2"></hr>
    //   </div>
    // </div>
    <div className="navbar">
      <div>
        <p>
          <a href="https://sadfridge.com/">sadfridge.com</a>
        </p>
      </div>
    </div>
  );
};

export default Header;
